@use "../../styles/index.scss" as *;

.container {
  margin-bottom: 100px;
}

.header {
  margin-left: 20px;
  padding-top: 50px;
}

.companyHeader {
  font-size: 24px !important;
  font-weight: 500 !important;
  padding-bottom: 0;
}

.companyTimeUpdated {
  font-size: 16px !important;
  font-weight: lighter;
  color: gray;
}

.appBarBtn {
  border-bottom: 5px transparent solid !important;
}

.appBarBtnActive {
  color: orange !important;
  border-bottom: 5px orange solid !important;
}

.buttonTextActive {
  font-weight: 600 !important;
}

.logo {
  width: 60px;
}

.ergoImage {
  width: 50px;
}

.username {
  text-align: center;
  margin: 0 auto;
}

.imageContainer { 
    justify-items: center;
    justify-content: center;
}

.linkOptions {
        margin-right: "30px";
        padding: "15px 30px";
        display: "block";
        background-color: "transparent";
        box-shadow: "none";
        text-transform: "none";
        border-radius: "0";
        color: 'black' !important;
}

