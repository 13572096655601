@use '../../styles/index.scss' as *;

.defaultButtonStyle{
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 5em;
    padding-right: 5em;
    background-color: $orange;
    border-radius: 10px;
    transition: 0.3s;
    &:hover{
        background-color: $medium-orange;
    }
}

.buttonTextStyle{
    color: $white;
    font-weight: 500;
}