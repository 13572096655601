@use '../../styles/index.scss' as *;

.header { 
    font-weight: bold;
    padding-top: 30px;
    padding-bottom: 15px;
}

.textGrid {
    text-align: left;
}

.viewButton, .viewButton:hover, .viewButton:active {
    background-color: orange !important;
    color: white !important;
    font-size: 26px !important;
    font-weight: bold !important;
    align-self: auto !important;
    padding: 1% 12.5% !important;
    border-radius: 56px !important;
    margin-top: 1% !important;

}