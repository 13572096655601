$black: black;
$white: white;
$light-gray: #FFFFFF;
$darkest-gray: #30364B;
$dark-gray: #343434;
$medium-gray: #6D6D6D;
$gray: #C0C0C0;
$orange: #F7941E;
$medium-orange: #FFBF53;
$light-orange: #FFD999;
$light-beige: #FFF3DE;
