@use '../../../styles/index.scss' as *;

.exportHeader {
    font-size: 40px !important;
    font-weight: bold !important;
    padding-bottom: 0;
}
.exportSubHeader {
    font-size: 16px !important;
    color: darkgray;
    padding-bottom: 0;
    font-weight: normal;
}

.radioHeader {
    font-size: 14px !important;
    padding-bottom: 0;
    margin-left: 15px;
    font-weight: normal;
}

.resetButton {
    font-size: 26px !important;
    border: none !important;
    background-color: white !important;
    color: black !important;
    border: 1px lightgray solid !important;
    text-transform: none !important;
    margin-right: 50px !important;
    padding: 0% 5% !important;
}

.resetButton:hover {
    background-color: #d6d2ce !important
}

.exportButton {
    font-size: 26px !important;
    border: none !important;
    color: white !important;
    background-color: orange !important;
    text-transform: none !important;
    padding: 0% 5% !important;

}

.exportButton:hover {
    background-color: #e2830f !important;
}