@use '../../styles/index.scss' as *;

.header { 
    font-weight: bold;
    padding-top: 30px;
    padding-bottom: 30px;
}

.textGrid {
    text-align: left;
}