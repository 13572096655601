.forgot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background-color: white;
    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    &__img {
        height: 3.5rem;
    }
    &__heading {
        font-size: 3rem;
        margin: 0;
        padding-top: 0.5rem;
    }
    &__letter {
        color: #F79A2B;
    }
    &__headline-div {
        margin: 2rem 0;
        text-align: center;
        line-height: 1.5;
    }
    &__headline {
        font-size: 1.5rem;
    }
    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__textfield-div {
        margin: 1rem 0;
        width: 25rem;
    }
    &__button-div {
        margin-top: 2rem;
        display: flex;
    }
    &__button {
        margin: 0 1rem !important;
        padding: 0.5rem 0;
        width: 17rem;
        height: 3rem;
        text-align: center;
        border-radius: 20px !important;
        font-weight: bold !important;
        &.btn-forgot {
            color: white;
            background-color: #F79A2B;
            border: none;
            border: 2px solid #F79A2B;
            transition-duration: 0.4s;
            &:hover {
                background-color: white;
                color: #F79A2B;
            }
        }
        &.btn-back {
            background-color: white;
            color: #F79A2B;
            border-style: none;
            border: 2px solid #F79A2B;
            transition-duration: 0.4s;
            transition-property: background-color;
            &:hover {
                background-color: #F79A2B;
                color: white;
                border: 2px solid #F79A2B;
            }
        }
    }
}