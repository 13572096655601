
// EmployeeList.module.scss
@use '../../styles/index.scss' as *;
@import '../../styles/colours.scss';

.dataGridContainer{
    height: 700px;
    min-height : 30%;
}

.title{
    font-size : 3rem;
    font-weight: bold;
    padding: 0 1.3rem;
}

.settingsBtn {
    font-weight: $bold !important;
    font-size: 1.5rem !important;
    text-transform: none !important;
    border: 2px solid $medium-gray !important;
}

.leaderboardHeader {
    font-size: 26px !important;
    font-weight: bold !important;
    padding-bottom: 0;
    text-align: left !important;
}
