@use '../../styles/index.scss' as *;

.container {
    margin-bottom: 100px;
}

.header {
    margin-left: 20px;
    padding-top: 50px;
}

.companyHeader {
    font-size: 24px !important;
    color: black;
    font-weight: 500 !important;
    padding-bottom: 0;
}

.companyTimeUpdated {
    font-size: 16px !important;
    font-weight: lighter;
    color: gray;
}

.appBarBtn{
    color: black !important;
    border-bottom: 5px transparent solid !important;
}

.appBarBtnActive {
    color: orange !important;
    border-bottom: 5px orange solid !important;
}

.buttonText {
    font-size: 18px !important; 
    font-weight: normal;
}