@use '../../styles/index.scss' as *;
@import '../../styles/colours.scss';

/*
Original
*/

// .headingText{
//     font-family: $heading;
//     font-weight: $bold;
// }

// .dataGridContainer{
//     height: 700px;
//     min-height : 30%;
//     font-family: $content;
//     font-weight: $normal;
// }

/*
Copied from EmployeeList.module.css
*/

.dataGridContainer{
    height: 700px;
    min-height : 30%;
}

.title{
    font-size : 3rem;
    font-weight: bold;
    padding: 1.3rem;
}

.settingsBtn {
    font-weight: $bold !important;
    font-size: 1.5rem !important;
    text-transform: none !important;
    border: 2px solid $medium-gray !important;
}
