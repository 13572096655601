@use '../../../styles/index.scss' as *;

.deskDataContainer {
    border-radius: 25px !important;
}

.deskHeader {
    font-size: 42px !important;
    color: black;
    font-weight: bold !important;
    padding-bottom: 0;
    text-align: left !important;
}

.mmmTimeUpdated {
    font-size: 28px;
    font-weight: bold;
    color: black;
}

.ddyyTimeUpdated {
    font-size: 24px;
    font-weight: lighter;
    color: black;
}

.chartValue {
    color: orange;
    text-align: center;
}

.viewButton, .viewButton:hover, .viewButton:active {
    background-color: orange !important;
    color: white !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    align-self: auto !important;
    padding: 5% 12% !important;
    border-radius: 56px !important;
}